import audioThumbnail from '@/components/media/audio/audio-thumbnail.vue'
import audioViewer from '@/components/media/audio/audio-viewer.vue'
import imageThumbnail from '@/components/media/image/image-thumbnail.vue'
import imageViewer from '@/components/media/image/image-viewer.vue'
import documentThumbnail from '@/components/media/document/document-thumbnail.vue'
import documentViewer from '@/components/media/document/document-viewer.vue'
import videoThumbnail from '@/components/media/video/video-thumbnail.vue'
import videoViewer from '@/components/media/video/video-viewer.vue'

const install = function(Vue) {
  Vue.component('ems-audio-thumbnail', audioThumbnail)
  Vue.component('ems-audio-viewer', audioViewer)

  Vue.component('ems-image-thumbnail', imageThumbnail)
  Vue.component('ems-image-viewer', imageViewer)

  Vue.component('ems-document-thumbnail', documentThumbnail)
  Vue.component('ems-document-viewer', documentViewer)

  Vue.component('ems-video-thumbnail', videoThumbnail)
  Vue.component('ems-video-viewer', videoViewer)
}

export default { install }
